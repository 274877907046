<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    Admin Panel
  </main>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
